<template>
  <v-container class="pa-0" fluid>
  
  </v-container>
</template>


<script>
export default {
  name: "DashboardIndex",
  data() {
    return {
      search: "",
    };
  },
};
</script>
